<template>
  <div class="auth donor">
    <div class="donor-form-block welcome">
      <img class="logo" src="/images/logo.svg">
      <div class="auth-title">
        <strong>歡迎</strong>
        <span>Welcome!</span>
      </div>
      <v-card
        color="#D4EEEF"
        elevation="0"
        class="text-center mb-10"
      >
        <v-card-title class="justify-center pb-2">{{ currentUser.nameZh }}<br>{{ currentUser.nameEn }}</v-card-title>
        <v-card-subtitle class="mt-0">{{ $moment().format('D MMM YYYY') }}</v-card-subtitle>
      </v-card>
      <div class="text mx-auto">
        <strong>請在下一頁輸入預計剩餘麵包數量 。 </strong>
        <span>Please estimate your available bread amount in the next screen.</span>
      </div>
      <v-btn
        color="primary"
        large
        max-width="100%"
        width="276"
        rounded
        class="mb-6 ml-auto mr-auto"
        to="/donors/estimation"
      >
        下一個 NEXT
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'welcome'
};
</script>
